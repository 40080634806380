<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list-header class="ion-margin-bottom">
      <h3>Chapters</h3>
    </ion-list-header>
    <ion-list
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item lines="none">
        <ion-card class="full-width">
          <ion-item lines="none" class="ion-no-padding">
            <ion-card-header>
              <ion-card-title>{{ item.name }} </ion-card-title>
            </ion-card-header>
            <ion-button
              @click="
                confirmDelete({
                  id: item.id,
                  name: item.name,
                  subjectId: item.subject_id,
                })
              "
              fill="clear"
              slot="end"
            >
              <ion-icon
                slot="icon-only"
                :ios="trashOutline"
                :md="trashSharp"
                size="small"
                style="color: #eb445a"
              ></ion-icon>
            </ion-button>
          </ion-item>
          <ion-card-content>
            {{ item.description }}
          </ion-card-content>
          <ion-item>
            <ion-button
              fill="solid"
              expand="full"
              :router-link="{
                name: 'chapter.progression',
                params: { chapter: item.id, description: description(item) },
              }"
              >Progression</ion-button
            >
            <ion-button
              slot="end"
              @click="setResourceToUpdate(item.id)"
              fill="clear"
            >
              <ion-icon
                slot="icon-only"
                :ios="createOutline"
                :md="createSharp"
                size="small"
                style="color: grey"
              ></ion-icon>
            </ion-button>
          </ion-item>
        </ion-card>
      </ion-item>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No chapter found</p>
    </ion-list-header>
  </ion-list>
  <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal title="Update chapter" @closeModal="setUpdateModalState(false)">
      <update-chapter
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-chapter>
    </base-modal>
  </ion-modal>
</template>
<script>
import BaseModal from "../../../Base/BaseModal.vue";
import UpdateChapter from "../Subject/UpdateChapter.vue";

import {
  IonList,
  IonListHeader,
  IonItem,
  IonModal,
  IonButton,
  IonIcon,
  alertController,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from "@ionic/vue";

import {
  trashOutline,
  trashSharp,
  createOutline,
  createSharp,
} from "ionicons/icons";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonModal,
    IonButton,
    IonIcon,
    BaseModal,
    UpdateChapter,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
  },
  data() {
    return {
      createOutline,
      createSharp,
      trashOutline,
      trashSharp,

      updateModalState: false,

      resourceToUpdate: {
        id: null,
        name: null,
      },

      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      const header = `Delete chapter: ${resource.name} ?`;
      const message = "This action cannot be undone.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource.id, resource.subjectId);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async delete(resourceId, subjectId) {
      try {
        const payLoad = {
          resourceId: resourceId,
          subjectId: subjectId,
        };

        await this.$store.dispatch("chapter/deleteResource", payLoad);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    setUpdateModalState(state) {
      this.updateModalState = state;
    },

    setResourceToUpdate(id) {
      const resource = this.resources.data.find(
        (resource) => resource.id === id
      );
      this.resourceToUpdate = resource;
      this.setUpdateModalState(true);
    },

    description(item) {
      return `Class ${item.subject.standard.name} ${item.subject.name}: ${item.name}`;
    },
  },
};
</script>
