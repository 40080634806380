<template>
  <form @submit.prevent="confirmUpdate()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>
          <ion-item :class="{ 'color-danger': v$.name.$error }">
            <ion-label position="floating">Chapter Name</ion-label>
            <ion-input type="text" v-model="name"></ion-input>
          </ion-item>
          <ion-item :class="{ 'color-danger': v$.description.$error }">
            <ion-label position="floating">Chapter Description</ion-label>
            <ion-textarea
              rows="15"
              type="text"
              v-model="description"
            ></ion-textarea>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItem,
  IonIcon,
  IonButton,
  IonInput,
  IonText,
  IonTextarea,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import { required, maxLength, integer, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  emits: ["closeModal"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItem,
    IonIcon,
    IonButton,
    IonInput,
    IonText,
    IonTextarea,
  },
  data() {
    return {
      id: this.resource.id,
      name: this.resource.name,
      description: this.resource.description,

      alertCircleOutline,
      alertCircleSharp,

      validationError: null,
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      name: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage("maximum 32 characters", maxLength(32)),
      },
      description: {
        required: helpers.withMessage("cannot be empty", required),
        maxLength: helpers.withMessage(
          "maximum 255 characters",
          maxLength(255)
        ),
      },
      id: {
        required: helpers.withMessage("cannot be empty", required),
        integer: helpers.withMessage("can only be integers", integer),
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmUpdate() {
      const header = `Confirm`;
      const message = "Are you sure you want to save changes?";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.updateChapter();
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async updateChapter() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        id: this.id,
        name: this.name,
        description: this.description,
        subject_id: this.resource.subject_id,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("chapter/updateResource", data);
        spinner.dismiss();

        this.name = null;
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },
  },
};
</script>