<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-chapter :resources="resources"></list-chapter>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="setCreateModalState(true)">
        <ion-icon :ios="addOutline" :md="addOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-modal
      :is-open="createModalState"
      @didDismiss="setCreateModalState(false)"
    >
      <base-modal
        title="Create Chapter"
        @closeModal="setCreateModalState(false)"
      >
        <create-chapter
          @closeModal="setCreateModalState(false)"
          :subjectId="subject"
        ></create-chapter>
      </base-modal>
    </ion-modal>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import BaseModal from "../../Base/BaseModal";
import CreateChapter from "../../Components/Academic/Subject/CreateChapter.vue";
import ListChapter from "../../Components/Academic/Subject/ListChapter.vue";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonIcon,
  IonFabButton,
  IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import { addOutline } from "ionicons/icons";

export default {
  props: ["subject", "title"],

  components: {
    BaseLayout,
    BaseModal,
    CreateChapter,
    ListChapter,
    IonGrid,
    IonRow,
    IonCol,
    IonFab,
    IonIcon,
    IonFabButton,
    IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      //   title: this.title,
      backLink: "academic",
      reloadable: true,

      createModalState: false,

      addOutline,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["chapter/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("chapter/resources", {
        refresh: refresh,
        resourceId: this.subject,
      });
    },
    loadData(event) {
      this.fetch(false);
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("chapter/resources", {
        refresh: true,
        resourceId: this.subject,
      });
      if (event) {
        event.target.complete();
      }
    },
    setCreateModalState(state) {
      this.createModalState = state;
    },
  },

  created() {
    this.fetch();
  },
};
</script>

<style scoped>
</style>